import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export const DownloadSVG = () => (
  <svg
    width="27px"
    height="36px"
    viewBox="0 0 27 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-384.000000, -28.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(384.000000, 28.000000)">
          <path d="M15.75,9.5625 L15.75,0 L1.6875,0 C0.75234375,0 0,0.75234375 0,1.6875 L0,34.3125 C0,35.2476563 0.75234375,36 1.6875,36 L25.3125,36 C26.2476563,36 27,35.2476563 27,34.3125 L27,11.25 L17.4375,11.25 C16.509375,11.25 15.75,10.490625 15.75,9.5625 Z M21.1253906,24.42375 L14.3458594,31.1526563 C13.8782813,31.6174219 13.123125,31.6174219 12.6555469,31.1526563 L5.87601562,24.42375 C5.16234375,23.7157031 5.66296875,22.5 6.66703125,22.5 L11.25,22.5 L11.25,16.875 C11.25,16.2534375 11.7534375,15.75 12.375,15.75 L14.625,15.75 C15.2465625,15.75 15.75,16.2534375 15.75,16.875 L15.75,22.5 L20.3329688,22.5 C21.3370313,22.5 21.8376563,23.7157031 21.1253906,24.42375 Z M26.5078125,7.3828125 L19.6242188,0.4921875 C19.3078125,0.17578125 18.8789063,0 18.4289063,0 L18,0 L18,9 L27,9 L27,8.57109375 C27,8.128125 26.8242188,7.69921875 26.5078125,7.3828125 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export const ExternalSVG = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-342 -176)" fill="none" fillRule="evenodd">
      <rect width="386" height="220" rx="4"></rect>
      <path
        d="M354 176c6.629 0 12 5.371 12 12s-5.371 12-12 12-12-5.371-12-12 5.371-12 12-12zm-1.398 6.948l3.653 3.504h-8.836c-.643 0-1.16.517-1.16 1.16v.775c0 .644.517 1.161 1.16 1.161h8.836l-3.653 3.504c-.47.45-.48 1.2-.02 1.66l.533.527c.454.455 1.19.455 1.64 0l6.42-6.416a1.156 1.156 0 000-1.64l-6.42-6.427a1.156 1.156 0 00-1.64 0l-.533.528c-.46.464-.45 1.214.02 1.664z"
        fillRule="nonzero"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export const Button = React.forwardRef(
  (
    {
      as: Component = 'button',
      children,
      type,
      htmlType,
      colour = 'blue',
      icon,
      iconPosition,
      downloadSize,
      ...props
    },
    ref
  ) => {
    switch (type) {
      case 'external':
        icon = <ExternalSVG />;
        break;
      case 'download':
        icon = <DownloadSVG />;
        break;
    }

    if (icon && !iconPosition) {
      iconPosition = 'right';
    }

    return (
      <Component
        className={cx(
          'button',
          type && `button--${type}`,
          `button--${colour}`,
          iconPosition && `button--icon-${iconPosition}`
        )}
        type={htmlType}
        ref={ref}
        disabled={props.disabled ? 'disabled' : undefined}
        {...props}
      >
        {downloadSize ? (
          <span>
            {children}
            <span className="button--download__size">PDF 408.4 KB</span>
          </span>
        ) : (
          children
        )}

        {icon && <span className="button__icon">{icon}</span>}
      </Component>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  as: PropTypes.string,
  icon: PropTypes.node,
  downloadSize: PropTypes.string,
  type: PropTypes.oneOf(['small']),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  colour: PropTypes.oneOf([
    'blue',
    'grey',
    'teal',
    'yellow',
    'external',
    'download',
  ]),
  onClick: PropTypes.func,
  htmlType: PropTypes.oneOf(['submit', 'button']),
};
