import React from 'react';
import PropTypes from 'prop-types';

import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { SignUpModalSignUpFlow } from './routes/signup';
import { SignUpModalLoginFlow } from './routes/login';
import { ChangeModalLoginFlow } from './routes/change';
import { BackControllerProvider } from './hooks/useBackController';

export const SignUpModal = props => {
  // api base : https://account.fc.development.ncvocloud.net/
  let defaultRedirectURI = props.postLoginRedirectURL;

  if (!defaultRedirectURI) {
    const url = new URL(location.href);
    url.hash = '';
    defaultRedirectURI = url.toString();
  }

  return (
    <BackControllerProvider
      backControllerBase={props.backControllerBase}
      postSignUpRedirectURL={props.postSignUpRedirectURL || defaultRedirectURI}
      postLoginRedirectURL={props.postLoginRedirectURL || defaultRedirectURI}
    >
      <Router>
        <Switch>
          {/* <Route exact path="/"></Route> */}
          <Route path="/signup">
            <SignUpModalSignUpFlow />
          </Route>
          <Route path="/login">
            <SignUpModalLoginFlow />
          </Route>
          <Route path="/change">
            <ChangeModalLoginFlow />
          </Route>
        </Switch>
      </Router>
    </BackControllerProvider>
  );
};

SignUpModal.propTypes = {
  postSignUpRedirectURL: PropTypes.string,
  postLoginRedirectURL: PropTypes.string,
  backControllerBase: PropTypes.string.isRequired,
};
