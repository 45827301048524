import React, {
  useCallback,
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from 'react';
import PropTypes, { checkPropTypes } from 'prop-types';
import { singleJoiningSlash } from '../../../lib/singleJoiningSlash';
import { userShape } from '../../../lib/userShape';

const contextTypes = {
  user: PropTypes.oneOfType([PropTypes.oneOf([null]), userShape]),
  fetchUser: PropTypes.func.isRequired,
  getLoginURL: PropTypes.func.isRequired,
  getApiRoute: PropTypes.func.isRequired,
  fetchAPI: PropTypes.func.isRequired,
  chooseOrganisation: PropTypes.func.isRequired,
  backControllerBase: PropTypes.string.isRequired,
  myAccountURL: PropTypes.string.isRequired,
  getBeginApplicationURL: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  postSignUpRedirectURL: PropTypes.string.isRequired,
  postLoginRedirectURL: PropTypes.string.isRequired,
};

const checkContextTypes = (props, componentName) => {
  checkPropTypes(
    contextTypes,
    props,
    'hooks.useBackController.checkContextTypes',
    componentName
  );

  return props;
};
const notImplemented = () => {
  throw new Error('Not implemented');
};
const asyncNotImplemented = async () => notImplemented();
const defaultContext = {
  user: null,
  fetchAPI: asyncNotImplemented,
  fetchUser: asyncNotImplemented,
  chooseOrganisation: asyncNotImplemented,
  getLoginURL: notImplemented,
  getApiRoute: notImplemented,
  backControllerBase: '',
  myAccountURL: '',
  getBeginApplicationURL: () => '',
  getCantFindOrgURl: () => '',
  postSignUpRedirectURL: '',
  postLoginRedirectURL: '',
  isLoading: false,
};

checkContextTypes(defaultContext, 'BackControllerContext.defaults');

export const BackControllerContext = createContext(defaultContext);

const usePaths = (base, defaultRedirectURI) => {
  const backControllerAPIBase = singleJoiningSlash(base, `api`);
  return useMemo(
    () => ({
      backControllerAPIBase,
      myAccountURL: singleJoiningSlash(base, `profile`),
      getBeginApplicationURL: organisationName => {
        /*************************************************************************************
         * Ticket https://github.com/NCVO-Digital/NCVO-BackController/issues/270             *
         * asks us to force this URL to go to https://www.ncvo.org.uk/get-involved/join/#/   *
         *************************************************************************************/
        // let url = singleJoiningSlash(base, `become-a-member`);
        //
        // // prefill organisation name
        // if (organisationName) {
        //   const entryParam = JSON.stringify({
        //     OrganisationDetails: { OrganisationName: organisationName },
        //   });
        //   url += `?entry=${encodeURIComponent(entryParam)}&new_org=true`;
        // }
        //
        // return url;
        return 'https://www.ncvo.org.uk/get-involved/join';
      },
      getCantFindOrgURl: organisationName => {
        let url = singleJoiningSlash(base, `i-cant-find-my-organisation`);

        // prefill organisation name
        if (organisationName) {
          const entryParam = JSON.stringify({
            OrganisationDetails: { OrganisationName: organisationName },
          });
          url += `?entry=${encodeURIComponent(entryParam)}&new_org=true`;
        }

        return url;
      },
      getLoginURL: postLoginRedirectURL =>
        singleJoiningSlash(
          base,
          `api/auth/login?fcReturnTo=${postLoginRedirectURL ||
            defaultRedirectURI}`
        ),
      getApiRoute: path => singleJoiningSlash(backControllerAPIBase, path),
    }),
    [backControllerAPIBase, base, defaultRedirectURI]
  );
};

const callAPI = async (url, opts = {}) => {
  const headers = { ...opts.headers };
  if (opts.body) {
    headers['content-type'] = 'application/json';

    if (typeof opts.body !== 'string') {
      opts.body = JSON.stringify(opts.body);
    }
  }
  const response = await fetch(url, {
    credentials: 'include',
    headers,
    ...opts,
  });

  if (!response.ok) {
    // console.error(
    //   `error calling api (${response.status})`,
    //   await response.text()
    // );
    throw new Error('error calling api');
  }

  return await response.json();
};

export const BackControllerProvider = ({
  children,
  backControllerBase,
  postSignUpRedirectURL,
  postLoginRedirectURL,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const paths = usePaths(backControllerBase, postLoginRedirectURL);
  const [user, setUser] = useState(null);
  const fetchAPI = useCallback(
    async (path, opts) => callAPI(paths.getApiRoute(path), opts),
    [paths]
  );
  const fetchUser = useCallback(async () => {
    let newUser;
    try {
      newUser = await callAPI(paths.getApiRoute('auth/whoami'));
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }

    setUser(newUser);
  }, [paths]);
  const chooseOrganisation = useCallback(
    async id => {
      const result = await fetchAPI('graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
          mutation UpdateViewer($id: ID!) {
            updateViewer(input: { organisationID: $id }) {
              id
            }
          }
        `,
          variables: { id },
        }),
      });

      const { data, errors } = result;

      if (errors?.length) {
        console.error(errors, data);
        throw new Error('Error calling graphql');
      }

      window.dispatchEvent(new Event('user-org-changed'));

      await fetchUser();
    },
    [fetchUser, fetchAPI]
  );

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <BackControllerContext.Provider
      value={checkContextTypes(
        {
          ...paths,
          user,
          fetchUser,
          backControllerBase,
          fetchAPI,
          isLoading,
          postSignUpRedirectURL,
          postLoginRedirectURL,
          chooseOrganisation,
        },
        'BackControllerProvider'
      )}
    >
      {children}
    </BackControllerContext.Provider>
  );
};
BackControllerProvider.propTypes = {
  backControllerBase: PropTypes.string.isRequired,
  children: PropTypes.node,
  postSignUpRedirectURL: PropTypes.string.isRequired,
  postLoginRedirectURL: PropTypes.string.isRequired,
};

export const useBackController = () => {
  const ctx = useContext(BackControllerContext);

  checkContextTypes(ctx, 'useBackController');

  return ctx;
};
