import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from '../../components/Layout';
import { TextInput } from '../../../text-input/TextInput';
import { Checkbox } from '../../../checkbox/Checkbox';
import { ContinueButton } from '../../components/ContinueButton';
import { useForm } from '../../hooks/useForm';
// copied to ../index
const validators = {
  givenName: val => !val && 'First name is required',
  familyName: val => !val && 'Last name is required',
};

export const NameCollection = ({ onSubmit, initialFormValues }) => {
  const { handleSubmit, errors, checkboxInput, textInput } = useForm({
    onSubmit,
    validators,
    initialFormValues,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Layout
        buttons={<ContinueButton htmlType="submit">Continue</ContinueButton>}
        link="./utilities/privacy-notice/#/"
      >
        <TextInput
          label="First name"
          {...textInput('givenName')}
          error={errors.givenName}
        />
        <TextInput
          label="Last name"
          {...textInput('familyName')}
          error={errors.familyName}
        />
        <fieldset>
          <div className="stack stack--vertical stack--left stack--space-3">
            <legend>
              <div className="rich-text text-1 mt-0 c-purple bold">
                <p className="text-3 pt-1 pb-1">About your role</p>
                <p>
                  We often have extra targeted email information for
                  organisational leaders. Are you:
                </p>
              </div>
            </legend>
            <Checkbox
              {...checkboxInput('jobRoles.Trustee')}
              label="a trustee?"
            />
            <Checkbox
              {...checkboxInput('jobRoles.CEO')}
              label="a ceo or other main decision maker for your organisation?"
            />
          </div>
        </fieldset>
      </Layout>
    </form>
  );
};

NameCollection.propTypes = {
  onSubmit: PropTypes.func,
  initialFormValues: PropTypes.shape({
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    jobRoles: PropTypes.object,
    leadershipStatus: PropTypes.object,
  }),
};
