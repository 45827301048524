import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

export const Modal = React.forwardRef(
  ({ onEscape, onOverlayClick, children, isOpen }, ref) => {
    if (!isOpen) {
      return null;
    }

    return (
      <FocusTrap>
        <div
          className="signup-modal"
          role="dialog"
          aria-label="Sign up modal"
          aria-modal="true"
          tabIndex="0"
          onKeyDown={evt => {
            if (evt.key === 'Escape') {
              onEscape();
            }
          }}
          ref={ref}
        >
          <div className="signup-modal__overlay" onClick={onOverlayClick}></div>
          <div
            className="signup-modal__overflow-wrapper"
            onClick={evt => {
              if (evt.target === evt.currentTarget) {
                onOverlayClick();
              }
            }}
          >
            <div className="signup-modal__layout-wrapper">{children}</div>
          </div>
        </div>
      </FocusTrap>
    );
  }
);

Modal.displayName = 'Modal';

Modal.propTypes = {
  onEscape: PropTypes.func,
  onOverlayClick: PropTypes.func,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};
