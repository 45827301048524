const toggle = (el, className) => {
  if (el.className.indexOf(className) === -1) {
    el.className += ` ${className}`;
  } else {
    el.className = el.className.replace(new RegExp(` ?${className}`), '');
  }
};

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', evt => {
    const parent = evt.target.parentElement;

    if (parent && parent.className.indexOf('w3-dropdown-click') !== -1) {
      const els = parent.querySelectorAll('.w3-dropdown-content');

      for (let el of els) {
        toggle(el, 'w3-show');
      }
    }
  });
});
