import React from 'react';
import PropTypes from 'prop-types';

export const IconMessageTick = props => {
  return (
    <div className="icon-message">
      <svg
        role="img"
        width="29px"
        height="29px"
        viewBox="0 0 29 29"
        version="1.1"
      >
        <title>tick 6</title>
        <g
          id="Sign-up-and-Log-in-states-"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="tick-icon" transform="translate(-578.000000, -314.000000)">
            <g id="Group-2" transform="translate(578.000000, 314.000000)">
              <circle
                id="Oval"
                fill="#89CBC0"
                cx="14.5"
                cy="14.5"
                r="14.5"
              ></circle>
              <g
                id="Group-6"
                transform="translate(7.975000, 9.425000)"
                fill="#FFFFFF"
              >
                <g id="Group-11" transform="translate(0.442912, 0.717454)">
                  <path
                    d="M11.4933824,0.831617647 C11.9526721,1.29090739 11.9526721,2.0355632 11.4933824,2.49485294 L5.39485294,8.59338235 C4.9355632,9.0526721 4.19090739,9.0526721 3.73161765,8.59338235 C3.2723279,8.13409261 3.2723279,7.3894368 3.73161765,6.93014706 L9.83014706,0.831617647 C10.2894368,0.372327903 11.0340926,0.372327903 11.4933824,0.831617647 Z"
                    id="Rectangle"
                  ></path>
                  <path
                    d="M0.790909091,4.41590909 C1.22771612,3.97910206 1.93592024,3.97910206 2.37272727,4.41590909 L5.00909091,7.05227273 C5.44589794,7.48907976 5.44589794,8.19728388 5.00909091,8.63409091 C4.57228388,9.07089794 3.86407976,9.07089794 3.42727273,8.63409091 L0.790909091,5.99772727 C0.354102061,5.56092024 0.354102061,4.85271612 0.790909091,4.41590909 Z"
                    id="Rectangle"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <strong className="icon-message__text">{props.children}</strong>
    </div>
  );
};

export const IconMessageCross = props => {
  return (
    <div className="icon-message">
      <svg
        role="img"
        width="29px"
        height="29px"
        viewBox="0 0 29 29"
        version="1.1"
      >
        <title>cross icon</title>
        <g
          id="Sign-up-and-Log-in-states-"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="cross-icon" transform="translate(-578.000000, -314.000000)">
            <g id="Group-2" transform="translate(578.000000, 314.000000)">
              <circle
                id="Oval"
                fill="#FF7575"
                cx="14.5"
                cy="14.5"
                r="14.5"
              ></circle>
              <g
                id="delete"
                transform="translate(9.000000, 9.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <path
                  d="M10.6281818,2.16662085 L7.29524651,5.499925 L10.6281818,8.83307125 C11.1239394,9.32902159 11.1239394,10.1323948 10.6281818,10.6283451 C10.3804766,10.8760519 10.0557189,11 9.73111903,11 C9.40598234,11 9.08119302,10.8762414 8.83367735,10.6283451 L5.50001579,7.29481995 L2.16660686,10.6283136 C1.91893329,10.8760203 1.59414397,11 1.26925992,11 C0.944470601,11 0.619902335,10.8762098 0.372007717,10.6283136 C-0.123749941,10.1325843 -0.123749941,9.32917948 0.372007717,8.83303967 L3.70484823,5.49989342 L0.371818243,2.16662085 C-0.123939414,1.67085999 -0.123939414,0.867297292 0.371818243,0.371536433 C0.867481164,-0.123845478 1.67056499,-0.123845478 2.16641738,0.371536433 L5.49998421,3.70484058 L8.83329841,0.371536433 C9.32924554,-0.123845478 10.1324241,-0.123845478 10.6279923,0.371536433 C11.1239394,0.867297292 11.1239394,1.67085999 10.6281818,2.16662085 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <strong className="icon-message__text">{props.children}</strong>
    </div>
  );
};

IconMessageTick.propTypes = {
  children: PropTypes.node,
};

IconMessageCross.propTypes = {
  children: PropTypes.node,
};
