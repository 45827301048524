import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from '../../../components/Layout';
import { ContinueButton } from '../../../components/ContinueButton';
import { TypeAhead } from '../../../../typeahead/TypeAhead';
import { Checkbox } from '../../../../checkbox/Checkbox';

export const ChooseOrganisation = ({
  onUpdateOrg,
  organisationFetchURL,
  userHasOrg,
  isLoading,
}) => {
  const [orgToConnect, setOrgToConnect] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [canConfirm, setCanConfirm] = useState(false);
  const canContinue =
    !isLoading && (canConfirm || (!userHasOrg && orgToConnect?.id));

  return (
    <Layout
      buttons={
        <div className="stack stack--space-3">
          <ContinueButton
            disabled={!canContinue}
            onClick={() => {
              onUpdateOrg(orgToConnect?.id);
            }}
          >
            Continue
          </ContinueButton>
        </div>
      }
    >
      {isLoading ? (
        <p className="text-1 m-4 c-purple bold center-text">
          Loading, please wait&hellip;
        </p>
      ) : (
        <>
          <div className="text-1 mx-4 c-purple bold center-text">
            <p className="mb-5">
              Enter the name of the organisation you want to connect with
            </p>
          </div>
          <TypeAhead
            labelText="Organisation"
            hint="Please type in the name of your organisation"
            url={organisationFetchURL}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            onChange={value => {
              setCanConfirm(false);
              setOrgToConnect(value?.[0]);
            }}
          />
          {orgToConnect && userHasOrg && (
            <div className="d-flex-end">
              <Checkbox
                checked={canConfirm}
                onChange={evt => setCanConfirm(evt.target.checked)}
                label={
                  <span className="c-purple">
                    By connecting with {orgToConnect.label} your email will no
                    longer be associated with your old organisation. Please
                    confirm that you wish to proceed
                  </span>
                }
              />
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

ChooseOrganisation.propTypes = {
  onUpdateOrg: PropTypes.func,
  organisationFetchURL: PropTypes.string.isRequired,
  userHasOrg: PropTypes.bool,
  isLoading: PropTypes.bool,
};
