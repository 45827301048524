import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const TextInput = ({ label, hint, error, ...props }) => {
  return (
    <label
      className={cx('text-input', {
        'text-input--error': !!error,
      })}
    >
      {label}
      <input className="text-input__input" type="text" {...props} />
      {error && <span className="text-input__error-hint">{error}</span>}
      {hint && <span className="text-input__hint">{hint}</span>}
    </label>
  );
};

TextInput.propTypes = {
  label: PropTypes.node,
  hint: PropTypes.node,
  error: PropTypes.node,
};
