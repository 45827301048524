import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { ContinueLink } from '../../components/ContinueLink';

export const LoginOrSignUp = ({ nextRoute = '', loginURL = '#' }) => {
  return (
    <Layout
      buttons={
        <div className="stack stack--space-3">
          <ContinueLink href={loginURL}>Log In now</ContinueLink>
          <Link to={nextRoute} component={ContinueLink}>
            Sign up for an account
          </Link>
        </div>
      }
      link="./utilities/privacy-notice/#/"
    >
      <p className="c-purple p-4 center-text bold">
        To join NCVO or access member-only resources, you need an NCVO account.
      </p>
    </Layout>
  );
};

LoginOrSignUp.propTypes = {
  nextRoute: PropTypes.string,
  loginURL: PropTypes.string,
};
