import React from 'react';
import PropTypes from 'prop-types';

export const Checkbox = ({ label, ...props }) => {
  return (
    <label className="checkbox">
      <span className="checkbox__text">{label}</span>
      <input className="checkbox__input" type="checkbox" {...props} />
      <span className="checkbox__custom-checkbox"></span>
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.node,
};
