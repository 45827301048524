import PropTypes from 'prop-types';
// TODO: use Typescript so we can share tpyes with backcontroller
export const userShape = PropTypes.shape({
  user_metadata: PropTypes.shape({
    emailUpdates: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    ncvo_createdOn: PropTypes.string,
    ncvo_modifiedOn: PropTypes.string,
    familyName: PropTypes.string,
    givenName: PropTypes.string,
  }),
  app_metadata: PropTypes.shape({
    organisation: PropTypes.shape({
      ncvo_etag: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
      ncvo_charityNumber: PropTypes.string,
      address: PropTypes.shape({
        postalCode: PropTypes.string,
      }),
      memberOf: PropTypes.shape({
        hostingOrganization: PropTypes.string,
        programName: PropTypes.string,
      }),
      ncvo_membershipStatus: PropTypes.number,
      ncvo_isMembershipOrganisation: PropTypes.bool,
    }),
    ncvo_organisationManager: PropTypes.bool,
  }),
  given_name: PropTypes.string,
  family_name: PropTypes.string,
  nickname: PropTypes.string,
  name: PropTypes.string,
  picture: PropTypes.string,
  updated_at: PropTypes.string,
  email: PropTypes.string,
  email_verified: PropTypes.bool,
  sub: PropTypes.string,
});
