import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../button/Button';
import { RightArrowInCircleSVG } from './RightArrowInCircleSVG';

// eslint-disable-next-line no-unused-vars
export const ContinueButton = ({ children, navigate, ...rest }) => (
  <Button type="small" icon={<RightArrowInCircleSVG />} {...rest}>
    {children}
  </Button>
);

ContinueButton.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  navigate: PropTypes.func,
};
