import React from 'react';
import PropTypes from 'prop-types';

export const Layout = ({ children, buttons, link }) => {
  return (
    <div className="signup-modal__layout">
      <div className="signup-modal__layout__header">
        <svg
          role="img"
          aria-labelledby="ncvo-logo"
          className="signup-modal__layout__logo"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 203.6 98.2"
        >
          <title id="ncvo-logo">NCVO logo</title>
          <path
            id="Fill-1"
            className="st0"
            d="M35,42.9v26.4L5.7,39.9H2.2c-1.2,0-2.2,1-2.2,2.2l0.1,53.1c0,1.2,1,2.2,2.2,2.2l11.6,0l0-30.2
            l30.3,30.4c0.3,0.3,0.9,0.6,1.5,0.6h0.9c1.2,0,2.2-1,2.2-2.2V40.6l-11.7,0.1C36,40.7,35,41.7,35,42.9"
          />
          <path
            id="Fill-3"
            className="st0"
            d="M176.5,83.9c-8.2,1.1-15.8-4.6-16.9-12.7c-1.2-8.3,4.6-15.9,12.7-17.1c8.2-1.1,15.8,4.6,17,12.9
            C190.5,75.2,184.7,82.8,176.5,83.9z M203.3,65.1c-2.2-16-17-27.1-32.9-24.9c-15.9,2.2-27,17-24.7,33c2.3,16.1,16.7,27,32.8,24.8
            C194.6,95.6,205.6,81.2,203.3,65.1L203.3,65.1z"
          />
          <path
            id="Fill-4"
            className="st0"
            d="M83.8,53.3c2.4,0,4.9,0.6,7.1,1.8c1.7,0.9,3.1,2.1,4.4,3.4l7.9-7.9c0.4-0.4,0.6-1,0.6-1.5
            c0-0.6-0.3-1.2-0.8-1.6c-5.7-5.2-11.8-7.5-19.6-7.5c-16.1,0-29.2,13.1-29.2,29.2c0,16,13.1,29.1,29.2,29.1c7.6,0,14.4-2.7,19.7-7.9
            c0.9-0.9,0.9-2.3,0.1-3.2l-0.1-0.1l-7.8-7.8c-1.6,1.3-3.1,2.6-5,3.4c-2,0.9-4.2,1.3-6.4,1.3c-8.3,0-14.8-6.7-14.8-15.3
            C69.1,60.1,75.5,53.3,83.8,53.3"
          />
          <path
            id="Fill-5"
            className="st0"
            d="M166.2,31.8l-10.6-6.1c-0.9-0.5-2-0.3-2.6,0.2l-30.2,17.4l0-34.9c0.1-0.7-0.3-1.8-1.1-2.3L111.1,0
            l-3,1.7L108,63.5c-0.1,0.9,0.4,1.8,1.2,2.2l0.5,0.3l0,0h0l0,0l0.5,0.3c0.8,0.4,1.7,0.4,2.5-0.1l53.5-31V31.8"
          />
        </svg>
        <h2 className="signup-modal__layout__title" title="Welcome">
          Welcome <span className="visually-hidden">log in and sign up</span>
        </h2>
      </div>
      <div className="signup-modal__layout__body">{children}</div>
      <div className="signup-modal__layout__button-bar">
        {buttons}
        <a className="signup-modal__layout__privacy-link mt-3" href={link}>
          Read our privacy policy
        </a>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  buttons: PropTypes.node,
  link: PropTypes.node,
};
