import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NameCollection } from './NameCollection';
import { AboutYourRole } from './AboutYourRole';
import { LoginOrSignUp } from './LoginOrSignUp';
import { SignUpOrganisation } from './SignUpOrganisation';
import { AddOrganisation } from './AddOrganisation';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { useBackController } from '../../hooks/useBackController';

const trueKeys = obj => Object.keys(obj || {}).filter(k => obj[k]);
const appendChild = (form, name, value) => {
  const el = document.createElement('input');

  el.name = name;
  el.value = value;

  form.appendChild(el);
};
const submitForm = (url, formValues) => {
  const form = document.createElement('form');
  form.style.display = 'none';
  form.action = url;
  form.method = 'POST';

  Object.entries(formValues).forEach(([key, value]) => {
    if (!value?.length || typeof value === 'string') {
      appendChild(form, key, value);
      return;
    }

    value.forEach(arrVal => {
      appendChild(form, key, arrVal);
    });
  });

  document.body.appendChild(form);

  form.submit();
};
const initFormVal = {
  givenName: '',
  familyName: '',
  // this will get transformed into an array of true values
  jobRoles: {},
  leadershipStatus: {},
};

const SignupRoutes = ({ onFinish }) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        render={props => <AddOrganisation {...props} />}
        path={`${url}/add-organisation`}
      />
      <Route path={[url, `${url}/organisation`]}>
        <SignUpOrganisation onFinish={onFinish} />
      </Route>
    </Switch>
  );
};

SignupRoutes.propTypes = {
  onFinish: PropTypes.func,
};

export const SignUpModalSignUpFlow = () => {
  const {
    getLoginURL,
    getApiRoute,
    postLoginRedirectURL,
    postSignUpRedirectURL,
    user,
  } = useBackController();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [signUpValues, setSignUpValues] = useState(initFormVal);
  const closeModal = () => history.push('/');

  const nameFormValid = useMemo(() => {
    return signUpValues.givenName && signUpValues.familyName;
  }, [signUpValues]);

  return (
    <Modal isOpen onEscape={closeModal} onOverlayClick={closeModal}>
      <Switch>
        <Route path={`${url}/name-collection`}>
          <NameCollection
            initialFormValues={signUpValues}
            onSubmit={async formValues => {
              setSignUpValues(oldValues => ({
                ...oldValues,
                ...formValues,
              }));

              history.push(`${url}/about-your-role`);
            }}
          />
        </Route>
        <Route path={`${url}/about-your-role`}>
          <AboutYourRole
            initialFormValues={signUpValues}
            onSubmit={async formValues => {
              const newFormValues = {
                ...signUpValues,
                ...formValues,
              };
              setSignUpValues(newFormValues);
              if (!nameFormValid) {
                history.push(`${url}/name-collection`);
                return;
              }
              const person = {
                ...newFormValues,
                leadershipStatus: trueKeys(newFormValues.leadershipStatus),
                jobRoles: trueKeys(newFormValues.jobRoles),
              };

              submitForm(
                getApiRoute(`auth/signup?fcReturnTo=${postSignUpRedirectURL}`),
                person
              );
            }}
          />
        </Route>
        {user?.email_verified && (
          <Route path={[path, 'signup']}>
            <SignupRoutes onFinish={closeModal} />
          </Route>
        )}
        <Route path={path}>
          <LoginOrSignUp
            nextRoute={`${url}/name-collection`}
            loginURL={getLoginURL(postLoginRedirectURL)}
          />
        </Route>
      </Switch>
    </Modal>
  );
};
