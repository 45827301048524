import React from 'react';
import ReactDOM from 'react-dom';
import { createModule } from '../../lib/createModule';
import { SignUpModal } from './SignUpModal';

let container;
let globalProps;

const render = defaultProps => {
  ReactDOM.render(
    <React.StrictMode>
      <SignUpModal {...defaultProps} {...globalProps} />
    </React.StrictMode>,
    container
  );
};

export const initSignUpModal = props => {
  if (!container) {
    container = document.createElement('div');
    container.className = 'signup-modal__root';
    document.body.appendChild(container);
  }

  globalProps = {
    ...globalProps,
    ...props,
  };

  render();
};

export const updateSignUpModal = props => {
  globalProps = {
    ...globalProps,
    ...props,
  };

  render();
};

createModule('signup-module', globalNamespace => {
  globalNamespace.initSignUpModal = initSignUpModal;
  globalNamespace.updateSignUpModal = updateSignUpModal;
});
