import React, { useCallback, useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { CheckOrgMembership } from '../login/organisation/CheckOrgMembership';
import { ChooseOrganisation } from '../login/organisation';
import { Modal } from '../../components/Modal';
import { useBackController } from '../../hooks/useBackController';

// mostly duplication of routes/login/index
export const ChangeModalLoginFlow = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const closeModal = () => history.push('/');
  const [isLoading, setIsLoading] = useState(false);
  // we do similar things in the signup/SignUpOrganisation.js
  const {
    user,
    getBeginApplicationURL,
    myAccountURL,
    getApiRoute,
    chooseOrganisation,
  } = useBackController();
  const userOrg = user?.app_metadata.organisation;
  const handleChangeOrg = useCallback(
    async id => {
      if (!id) {
        return;
      }

      setIsLoading(true);

      await chooseOrganisation(id);

      history.push(`${url}/organisation/membership`);
      setIsLoading(false);
    },
    [chooseOrganisation, history, url]
  );

  return (
    <Modal
      isOpen={user?.email_verified}
      onEscape={closeModal}
      onOverlayClick={closeModal}
    >
      <Switch>
        {userOrg && (
          <Route path={`${url}/organisation/membership`}>
            <CheckOrgMembership
              selectOrgURL={`${url}/organisation`}
              isMembershipOrganisation={userOrg.ncvo_isMembershipOrganisation}
              orgName={userOrg.name}
              myAccountURL={myAccountURL}
              beginApplicationURL={getBeginApplicationURL(userOrg.name)}
              withCloseControl
            />
          </Route>
        )}
        <Route path={`${url}/organisation`}>
          <ChooseOrganisation
            isLoading={isLoading}
            onUpdateOrg={handleChangeOrg}
            organisationFetchURL={getApiRoute('organisation/search')}
            userHasOrg={!!userOrg}
          />
        </Route>
      </Switch>
    </Modal>
  );
};
