import React from 'react';

export const RightArrowInCircleSVG = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-342 -176)" fill="none" fillRule="evenodd">
      <rect width="386" height="220" rx="4"></rect>
      <path
        d="M354 176c6.629 0 12 5.371 12 12s-5.371 12-12 12-12-5.371-12-12 5.371-12 12-12zm-1.398 6.948l3.653 3.504h-8.836c-.643 0-1.16.517-1.16 1.16v.775c0 .644.517 1.161 1.16 1.161h8.836l-3.653 3.504c-.47.45-.48 1.2-.02 1.66l.533.527c.454.455 1.19.455 1.64 0l6.42-6.416a1.156 1.156 0 000-1.64l-6.42-6.427a1.156 1.156 0 00-1.64 0l-.533.528c-.46.464-.45 1.214.02 1.664z"
        fillRule="nonzero"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);
