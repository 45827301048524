import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from '../../components/Layout';
import { ContinueButton } from '../../components/ContinueButton';
import { TypeAhead } from '../../../typeahead/TypeAhead';
import {
  IconMessageCross,
  IconMessageTick,
} from '../../../icon-message/IconMessage';
import { useBackController } from '../../hooks/useBackController';
import { ContinueLink } from '../../components/ContinueLink';
import { TextInput } from '../../../text-input/TextInput';

const OrganisationIsMember = ({ name }) => {
  return <IconMessageTick>Great, {name} is already a member</IconMessageTick>;
};

const OrganisationIsNotMember = ({ name }) => {
  return (
    <>
      <IconMessageCross>{name} is not a current member</IconMessageCross>
      <p className="rich-text c-purple center-text bold mt-4">
        Are you ready for your organisation to join NCVO?
      </p>
    </>
  );
};

OrganisationIsMember.propTypes = OrganisationIsNotMember.propTypes = {
  name: PropTypes.string.isRequired,
};

export const SignUpOrganisation = ({ onFinish }) => {
  // we do similar things in the login/index.js
  const {
    user,
    getBeginApplicationURL,
    getApiRoute,
    chooseOrganisation,
  } = useBackController();
  const userOrg = user?.app_metadata.organisation;
  const isOrganisationMember = userOrg?.ncvo_isMembershipOrganisation;
  const [chosenOrg, setChosenOrg] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [isAssigningOrg, setIsAssigningOrg] = useState(false);

  const handleConfirmSelect = useCallback(async () => {
    if (!chosenOrg?.id) {
      return;
    }

    setIsAssigningOrg(true);
    await chooseOrganisation(chosenOrg.id);
    setIsAssigningOrg(false);
  }, [chooseOrganisation, chosenOrg]);

  const bodyCopy = useMemo(() => {
    if (!userOrg) {
      return null;
    }

    if (isOrganisationMember) {
      return <OrganisationIsMember name={userOrg.name} />;
    } else {
      return <OrganisationIsNotMember name={userOrg.name} />;
    }
  }, [isOrganisationMember, userOrg]);

  const canContinue = !isAssigningOrg && !isFetching && (userOrg || chosenOrg);

  const buttons = useMemo(() => {
    if (!userOrg) {
      return (
        <ContinueButton
          disabled={!canContinue}
          onClick={handleConfirmSelect}
          dataTarg="SignUpOrganisation"
        >
          Continue
        </ContinueButton>
      );
    }

    if (isOrganisationMember) {
      return (
        <ContinueButton
          disabled={!canContinue}
          onClick={onFinish}
          dataTarg="SignUpOrganisation"
        >
          Continue
        </ContinueButton>
      );
    } else {
      return (
        <ContinueLink
          href={getBeginApplicationURL(userOrg)}
          dataTarg="SignUpOrganisation"
        >
          Yes, begin application
        </ContinueLink>
      );
    }
  }, [
    isOrganisationMember,
    userOrg,
    onFinish,
    handleConfirmSelect,
    getBeginApplicationURL,
    canContinue,
  ]);

  return (
    <Layout buttons={buttons}>
      {isAssigningOrg ? (
        <p className="text-1 m-4 c-purple bold center-text">
          Loading, please wait&hellip;
        </p>
      ) : (
        <div className="stack stack--vertical stack--left stack--space-8">
          {userOrg ? (
            <TextInput label="Organisation" value={userOrg.name} readOnly />
          ) : (
            <TypeAhead
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              labelText="Organisation"
              hint="Please type in the name of your organisation"
              url={getApiRoute('organisation/search')}
              onChange={value => setChosenOrg(value?.[0])}
            />
          )}

          {bodyCopy && <div className="d-flex-center-x">{bodyCopy}</div>}
        </div>
      )}
    </Layout>
  );
};

SignUpOrganisation.propTypes = {
  routePrefix: PropTypes.string,
  onFinish: PropTypes.func,
};
