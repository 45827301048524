import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'core-js/features/url-search-params';
import { Layout } from '../../components/Layout';
import { ContinueLink } from '../../components/ContinueLink';
import { TextInput } from '../../../text-input/TextInput';
import { useBackController } from '../../hooks/useBackController';

export const AddOrganisation = ({ location }) => {
  const params =
    location?.search && new URLSearchParams(location.search.slice(1));
  const prefillOrganisation = params?.get('prefillOrganisation');
  const [organisationName, setOrganisationName] = useState(
    prefillOrganisation || ''
  );
  const { getBeginApplicationURL, getCantFindOrgURl } = useBackController();

  useEffect(() => {
    if (prefillOrganisation) {
      setOrganisationName(prefillOrganisation);
    }
  }, [prefillOrganisation]);

  return (
    <Layout
      buttons={
        <>
          <ContinueLink
            href={getCantFindOrgURl(organisationName)}
            dataTarg="AddOrganisation"
          >
            Continue
          </ContinueLink>

          <p className="m-2 text-1 c-text-grey">
            If you want to find out more about joining NCVO, head to our{' '}
            <a href="/join">join page</a>.
          </p>
        </>
      }
    >
      <p className="my-6 c-purple center-text bold">
        No problem, give us your organisation name
      </p>

      <TextInput
        label="Organisation name"
        value={organisationName}
        onChange={event => setOrganisationName(event.target.value)}
      />
    </Layout>
  );
};

AddOrganisation.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
