import { useCallback, useState } from 'react';
import clone from 'lodash.clonedeep';

const getNameValue = el => [el.name, el.value];
const getCheckboxValue = el => [el.name, el.checked];

const deepKeySet = (root, key, value) => {
  const out = clone(root);
  let [nextKey, ...parts] = key.split('.');

  if (!parts.length) {
    out[nextKey] = value;

    return out;
  }

  let nextParent = out;

  parts.forEach(p => {
    if (nextParent[nextKey] === undefined) {
      nextParent[nextKey] = {};
    }

    nextParent = nextParent[nextKey];
    nextKey = p;
  });

  nextParent[nextKey] = value;

  return out;
};

const deepKeyGet = (root, key) => {
  if (root == undefined) {
    return undefined;
  }

  const [first, ...rest] = key.split('.');

  if (!rest.length) {
    return root[first];
  }

  return deepKeyGet(root[first], rest.join('.'));
};

/**
 * A simple hook to be used when showing a form
 *
 * @usage
 * const { text, handleSubmit, errors } = useForm({
 *   onSubmit(values) {console.log(values)}
 *   validators: {
 *     firstName: value => !value && 'first name is required'
 *   }
 * })
 * ...
 * <form onSubmit={handleSubmit}>
 *   <label>firstname: <input type="text" {...text('firstName')} /></label>
 *   <p>{errors['firstName']}</p>
 *   <button>submit</button>
 * </form>
 * @param {args} object is the options defined below
 *   @param {onSubmit} onSubmit is called with the form is submitted successfully
 *   @param {validators} validators is an object which will be called on submit to validate fields
 *   @param {initialFormValues} initialFormValues is the object which can be used to set initial values
 * @returns
 */
export const useForm = ({ onSubmit, validators = {}, initialFormValues }) => {
  const [formValues, setFormValues] = useState(initialFormValues || {});
  const [formErrors, setFormErrors] = useState({});

  const updateFormValues = useCallback((key, value) => {
    setFormValues(prevState => deepKeySet(prevState, key, value));
    setFormErrors(prevState => ({ ...prevState, [key]: null }));
  }, []);

  const textChangeHandler = useCallback(
    evt => {
      const [key, value] = getNameValue(evt.currentTarget);

      updateFormValues(key, value);
    },
    [updateFormValues]
  );

  const checkboxChangeHandler = useCallback(
    evt => {
      const [key, value] = getCheckboxValue(evt.currentTarget);

      updateFormValues(key, value);
    },
    [updateFormValues]
  );

  return {
    errors: formErrors,
    handleSubmit: useCallback(
      evt => {
        evt.preventDefault();
        const errors = {};
        let hasErrors = false;

        Object.entries(validators).forEach(([name, validate]) => {
          const err = validate(formValues[name], name);

          if (err) {
            hasErrors = true;
            errors[name] = err;
          }
        });

        if (hasErrors) {
          setFormErrors(errors);
          return;
        }

        onSubmit(formValues);
      },
      [formValues, onSubmit, validators]
    ),
    textInput: useCallback(
      name => ({
        onChange: textChangeHandler,
        name,
        value: deepKeyGet(formValues, name) || '',
      }),
      [textChangeHandler, formValues]
    ),
    checkboxInput: useCallback(
      name => ({
        onChange: checkboxChangeHandler,
        name,
        checked: deepKeyGet(formValues, name) || false,
      }),
      [checkboxChangeHandler, formValues]
    ),
  };
};
