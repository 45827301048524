import { createPopper } from '@popperjs/core';

window.addEventListener('manage-people-loaded', () => {
  const pageEl = document.querySelector('.page');
  const tooltipEls = document.querySelectorAll('.org-member-strip__actions');

  [].forEach.call(tooltipEls, function(tooltipEl) {
    const triggerEl = tooltipEl.parentElement.querySelector(
      '.org-member-strip__toggle-more'
    );

    createPopper(triggerEl, tooltipEl, {
      boundary: pageEl,
      placement: 'left',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-20, 8],
          },
        },
      ],
    });
  });
});
