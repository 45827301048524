import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../../checkbox/Checkbox';
import { Layout } from '../../components/Layout';
import { ContinueButton } from '../../components/ContinueButton';
import { useForm } from '../../hooks/useForm';

export const AboutYourRole = ({ onSubmit, initialFormValues }) => {
  const { checkboxInput, handleSubmit } = useForm({
    onSubmit,
    initialFormValues,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Layout
        buttons={<ContinueButton htmlType="submit">Continue</ContinueButton>}
        link="./utilities/privacy-notice/#/"
      >
        <div>
          <div className="rich-text text-1 mt-3 c-purple">
            <h3 className="heading heading--small">About your role</h3>
            <fieldset>
              <legend>Which of these areas do you cover?</legend>
              <div className="mt-3">
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Managing day to day operations"
                        {...checkboxInput(
                          'leadershipStatus.Managing day to day operations'
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Governance"
                        {...checkboxInput('leadershipStatus.Governance')}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Strategy"
                        {...checkboxInput('leadershipStatus.Strategy')}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Impact and evaluation"
                        {...checkboxInput(
                          'leadershipStatus.Impact and evaluation'
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Managing or supporting volunteers"
                        {...checkboxInput(
                          'leadershipStatus.Managing or supporting volunteers'
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Financial"
                        {...checkboxInput('leadershipStatus.Financial')}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Funding"
                        {...checkboxInput('leadershipStatus.Funding')}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Marketing and communication"
                        {...checkboxInput(
                          'leadershipStatus.Marketing and communication'
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="HR"
                        {...checkboxInput('leadershipStatus.HR')}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="d-flex-center-y">
                      <Checkbox
                        label="Something else"
                        {...checkboxInput('leadershipStatus.Something else')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </Layout>
    </form>
  );
};

AboutYourRole.propTypes = {
  onSubmit: PropTypes.func,
  initialFormValues: PropTypes.object,
};
