import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { ContinueLink } from '../../../components/ContinueLink';
import {
  IconMessageCross,
  IconMessageTick,
} from '../../../../icon-message/IconMessage';

const OrganisationIsMember = ({ orgName }) => {
  return (
    <IconMessageTick>{`${orgName} has an active membership`}</IconMessageTick>
  );
};

OrganisationIsMember.propTypes = {
  orgName: PropTypes.string,
};

const OrganisationIsNotMember = ({ orgName }) => {
  return (
    <>
      <IconMessageCross>
        {`${orgName} does not have an active membership`}
      </IconMessageCross>
    </>
  );
};

OrganisationIsNotMember.propTypes = {
  orgName: PropTypes.string,
};

export const CheckOrgMembership = ({
  selectOrgURL,
  isMembershipOrganisation,
  orgName,
  myAccountURL,
  beginApplicationURL,
  withCloseControl = false,
}) => {
  const history = useHistory();

  const orgMembershipMessage = useMemo(() => {
    if (isMembershipOrganisation) {
      return <OrganisationIsMember orgName={orgName} />;
    } else {
      return <OrganisationIsNotMember orgName={orgName} />;
    }
  }, [isMembershipOrganisation, orgName]);

  return (
    <Layout
      buttons={
        <div className="stack stack--space-3">
          <p className="text-1 c-purple bold center-text">
            {isMembershipOrganisation
              ? 'Is this the right organisation for you?'
              : 'Is this the organisation that you want to apply to join NCVO?'}
          </p>

          {isMembershipOrganisation ? (
            <>
              {withCloseControl ? (
                <ContinueLink
                  as="button"
                  onClick={() => {
                    history.push('/');
                  }}
                  dataTarg="CheckOrgMembership"
                >
                  Continue
                </ContinueLink>
              ) : (
                <ContinueLink href={myAccountURL}>
                  Yes, go to my account
                </ContinueLink>
              )}
            </>
          ) : (
            <ContinueLink
              href={beginApplicationURL}
              dataTarg="CheckOrgMembership"
            >
              Yes, begin application
            </ContinueLink>
          )}

          <Link className="link mx-3" to={selectOrgURL}>
            No, I need to connect with a different organisation
          </Link>
        </div>
      }
    >
      <div className="text-1 mx-4 c-purple">
        <p className="my-5 bold">
          Your email is now connected with {orgName} organisation
        </p>
        {orgMembershipMessage}
      </div>
    </Layout>
  );
};

CheckOrgMembership.propTypes = {
  selectOrgURL: PropTypes.string,
  isMembershipOrganisation: PropTypes.bool,
  orgName: PropTypes.string,
  myAccountURL: PropTypes.string,
  beginApplicationURL: PropTypes.string,
  withCloseControl: PropTypes.bool,
};
